import { Note } from 'entities/Note';
import { Warehouse } from 'entities/Warehouse';

export interface UserSchema {
  userData: Nullable<User>;
  credentials: Nullable<AuthResponse>;
}

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  dateOfBirth: string;
  street: string;
  house: string;
  zip: string;
  apartment: string;
  email: string;
  country: string;
  city: string;
  language: string;
  identificationNumber?: string;
  accountType: UserAccountType;
  role: UserRoles;
  warehouses: Warehouse[];
  createdNotes?: Note[];
  createdAt: string;
  note?: string;
  balance: Nullable<number>;
}

export interface UpdateUserParams extends User {
  password?: string;
}

export enum UserRoles {
  SUPERUSER = 'superuser',
  MANAGER = 'manager',
  CUSTOMER = 'customer',
}

export enum UserAccountType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export interface RegisterUserParams {
  firstName?: string;
  lastName?: string;
  company?: string;
  email: string;
  phone?: string;
  country?: string;
  dateOfBirth?: string;
  street?: string;
  house?: string;
  zip?: string;
  apartment?: string;
  city: string;
  language: string;
  identificationNumber?: string;
  accountType: UserAccountType;
  password: string;
}

export interface CompleteRegisterUserParams {
  firstName: string;
  lastName: string;
  company?: string;
  phone: string;
  country: string;
  dateOfBirth: string;
  street: string;
  house: string;
  zip: string;
  apartment?: string;
  city: string;
  language: string;
  identificationNumber?: string;
  accountType: UserAccountType;
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  email?: string;
}

export interface JWTUser {
  userId: string;
  role: string;
  assignedWarehouseIds: string[];
  email: string;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface UserForListing extends User {
  contractDebt: number;
  balance: number;
}

export type ChangeUserAddressParams = Pick<UpdateUserParams, 'country' | 'city' | 'street' | 'zip' | 'house' | 'apartment'>;
